import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import * as Sentry from '@sentry/react';
import Astrolytics from 'astrolytics-browser';
import posthog from 'posthog-js';
import Splash from './components/Splash';
import reportWebVitals from './reportWebVitals';
import ErrorFallback from './components/ErrorFallback';
import storage from './utils/storage';

// Astrolytics.init('64bae874882a21e5ab976453', {
//   debug: true,
//   endpoint: 'ws://localhost:3002',
// });

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://437cdcce4c044ac1aecd7d6583f78c17@o4505202419826688.ingest.sentry.io/4505398864314368',
  });
  posthog.init('phc_hydHI6RzLhxO3md9X8eLDA3PjgGlG6nGzIzCYNZXP5V');
  Astrolytics.init('64b9695d5b5bbb979e247773');
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const localStoragePersister = createSyncStoragePersister({ storage });

persistQueryClient({
  queryClient,
  persister: localStoragePersister,
});

// Async to show splash screen when loading
const App = React.lazy(() => import('./App'));

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={ErrorFallback}
    showDialog
  >
    <Suspense fallback={<Splash />}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </QueryClientProvider>
    </Suspense>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
