class StorageAdapter {
  constructor() {
    this.memoryStorage = {};
    try {
      // Attempt to access localStorage
      window.localStorage.setItem('test', 'test');
      window.localStorage.removeItem('test');
      this.hasLocalStorage = true;
    } catch (e) {
      console.log('failed to access localStorage');
      // If any error occurs, we'll assume localStorage isn't available
      this.hasLocalStorage = false;
    }
  }

  setItem(key, value) {
    if (this.hasLocalStorage) {
      window.localStorage.setItem(key, value);
    } else {
      this.memoryStorage[key] = String(value); // Always store as string to mimic localStorage
    }
  }

  getItem(key) {
    if (this.hasLocalStorage) {
      return window.localStorage.getItem(key);
    }
    if (key in this.memoryStorage) {
      return this.memoryStorage[key];
    }

    return null; // Mimic localStorage behavior
  }

  removeItem(key) {
    if (this.hasLocalStorage) {
      window.localStorage.removeItem(key);
    } else {
      delete this.memoryStorage[key];
    }
  }

  clear() {
    if (this.hasLocalStorage) {
      window.localStorage.clear();
    } else {
      this.memoryStorage = {};
    }
  }

  key(n) {
    if (this.hasLocalStorage) {
      return window.localStorage.key(n);
    }
    const keys = Object.keys(this.memoryStorage);

    return keys[n] || null;
  }

  get length() {
    if (this.hasLocalStorage) {
      return window.localStorage.length;
    }

    return Object.keys(this.memoryStorage).length;
  }
}

const storage = new StorageAdapter();

export default storage;
