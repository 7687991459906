import React, { useState, useEffect } from 'react';
import { ReactComponent as Logo } from '../icons/icon.svg';
import '../index.css';

const logoStyles = {
  boxShadow: '0 0 0 0 rgba(24, 144, 255, 0.8)',
  borderRadius: '17px',
  marginBottom: '20px',
  animation: 'megapulse 2s infinite',
};

export default function Splash() {
  const [text, setText] = useState('Your dashboard is loading...');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer0 = setTimeout(() => {
      setVisible(true);
    }, 700);

    const timer1 = setTimeout(() => {
      setText("We're almost there...");
    }, 6000);

    const timer2 = setTimeout(() => {
      setText("It's taking longer than usual...");
    }, 15000);

    return () => {
      clearTimeout(timer0);
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  if (!visible) return null;

  return (
    <div
      className="fixed inset-0 flex min-h-screen w-screen items-center
                    justify-center text-center
                    font-sans text-3xl leading-9 text-gray-900"
    >
      <div>
        <Logo style={logoStyles} />
        <br />
        <p className="m-0">
          {text}
        </p>
      </div>
    </div>
  );
}

