import React from 'react';
import { Result } from 'antd';

export default function ErrorFallback() {
  return (
    <Result
      status="warning"
      title="There was an error rendering this page."
      extra={
        <p>
          Please
          {' '}
          <a href="/">
            refresh
          </a>
          {' '}
          the page or contact support.
        </p>
      }
    />
  );
}
